import axios from '@axios'
import { queryEvents, updateEvent, addEvent, removeEvent } from '@/@core/queries/calendar'
import { addImprovement, updateImprovement } from '@/@core/queries/improvements'
import store from '@/store'
import { get } from 'idb-keyval';

const updateLocationConditions = (items, category, query) => {
  const filterQuery = items.map(e => ({locations_in: {[category]: e}}))
  query.OR[0].attendee.AND.push({OR: filterQuery})
  query.OR[1].attendee.AND.push({OR: filterQuery})
  query.OR[2].AND.push({organizer: {OR: filterQuery}})
  if (query.OR[3]) query.OR[3].attendee.AND.push({OR: filterQuery})
}

const dateStringToDate = (dateTimeString) => {
  if (dateTimeString.includes("T")) {
    // ISO 8601 format (e.g. 2025-01-17T15:00:00.000Z)
    return new Date(dateTimeString);
  } else {
    // dd-mm-yyyy hh:mm format
    const [datePart, timePart] = dateTimeString.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes] = timePart ? timePart.split(":").map(Number) : [0, 0];
    return new Date(year, month - 1, day, hours, minutes);
  }
}

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'warning',
        label: 'Pendientes',
      },
      {
        color: 'danger',
        label: 'Vencidas',
      },
      {
        color: 'success',
        label: 'Realizadas',
      },
      {
        color: 'info',
        label: 'Futuras',
      },
      {
        color: 'warning',
        label: 'En espera',
      },
    ],
    selectedCalendars: ['Pendientes', 'Vencidas', 'En espera'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, locations, roles, workers, month, year, zones, agencies, onlyInstances }) {
      // TODO: This filter should be at the backend
      const getUserData = store.state?.userStore?.userData
      const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
      const userLocationsQuery = getUserData.locations?.map(e => ({ _id: e.value }))

      const firstDayOfCurrentMonth = new Date(year, month, 1)
      const firstDayOfNextMonth = new Date(year, month + 1, 1)

      const query = {
        client_id: (getUserData.role != 'admin') ? { _id: getUserData?.client?.$oid} : null,
        AND: [{ OR: [{ extendedProps: { calendar_in: calendars } }] }],
        //Set query to fetch only the events in the selected month and year
        start_gte: firstDayOfCurrentMonth,
        start_lt: firstDayOfNextMonth,
        deleted_ne: true,
        OR: [
          { attendee: { AND: [{ OR: [{ deleted: false }, { deleted_exists: false }] }] } },
          { 
            attendee: { AND: [{ deleted: true }] },
            extendedProps: { calendar: "Realizadas"}
          },
          { AND: [{ attendee_exists: false }]}
        ],
      }

      if (calendars.includes('En espera')) {
        query.AND[0].OR[1] = { confirmation: { pending: true } }
      } else {
        query.AND[1] = { OR: [ { confirmation_exists: false }, { confirmation: { pending_ne: true } }] }
      }

      if (getUserData.role === 'supervisor') {
        query.OR[0].attendee.AND.push({supervisors_in: {_id: userId}})
        query.OR[1].attendee.AND.push({supervisors_in: {_id: userId}})
        query.OR[2].AND.push({organizer: {locations_in: userLocationsQuery}})
        query.OR[3] = {attendee: {AND: [{_id: userId}]}}
      }

      if (locations.length > 0) updateLocationConditions(locations, "_id", query)
      if (zones.length > 0) updateLocationConditions(zones, "zone", query)
      if (agencies.length > 0) updateLocationConditions(agencies, "agency", query)

      if (roles.length > 0) {
        query.attendee = {roles_in: roles}
      }

      if (workers.length > 0) {
        if (query.attendee) query.attendee._id_in = workers
        else query.attendee = {_id_in: workers}
      }

      if (onlyInstances) {
        query.isInstance = true
      }

      return new Promise((resolve, reject) => {
        const isOnline = store.state.app.isOnline
        if (isOnline) {
          // eslint-disable-next-line import/no-named-as-default-member
          axios
            .post('/graphql', {
              query: queryEvents,
              variables: {
                query,
                limit: 10000,
              },
            })
            .then(response => {
              if (response.data.errors) throw new Error(response.data.errors[0].message)
              const events = response.data?.data?.events
              resolve(events)
            })
            .catch(error => {
              reject(error)
            })
        }
        else {
          get('events').then(response => {
            if (response) resolve(response)
            else if (localStorage.events) resolve(JSON.parse(localStorage.events))
            else reject({ isStorageError: true })
          })
        }
      })
    },

    async addEvent(ctx, { event, singleUpload }) {
      const getUserData = store.state?.userStore?.userData

      const newEvent = {
        client_id: getUserData?.client?.$oid ? { link: getUserData.client.$oid } : null,
        isInstance: true,
        extendedProps: { calendar: 'Pendientes' },
        start: event.start instanceof Date ? event.start : new Date(event.start),
        end: event.end instanceof Date ? event.end : new Date(event.end),
        title: event.title
      }

      if (newEvent.end <= newEvent.start) {
        newEvent.end = new Date(newEvent.start)
        newEvent.end.setHours(newEvent.end.getHours() + 1)
      }

      if (event.extendedProps.metadata?.length) {
        newEvent.metadata = event.extendedProps.metadata.map(m => ({
          name: m.name,
          type: m.type,
          answer: m.answer,
        }))
      }

      if (event.extendedProps.participants?.length) {
        newEvent.participants = { link: event.extendedProps.participants }
      }

      if (event.extendedProps.improvements?.length && singleUpload) {
        // Upload images to AWS and then store image keys in an array
        const uploadImagePromises = event.extendedProps.improvements.map(i => {
          if (!i.imgData?.fileInfo) return null
          const { fileInfo, destinationFolder } = i.imgData
          return new Promise((resolve, reject) => {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => resolve(key))
              .catch((err) => {
                console.log(err)
                resolve(null)
              })
          })
        })
        
      const imageKeys = await Promise.all(uploadImagePromises)

        // Set improvements property
      newEvent.improvements = {
        create: event.extendedProps.improvements.map((i, index) => {
          const improvement = {
            client_id: i.client_id,
            assignee: i.assignee ? { link: i.assignee._id } : null,
            note: i.note,
            tags: i.tags,
            description: i.description,
            dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
            important: i.important,
          }
          if (i.imgData) improvement.imageKey = imageKeys[index]
          if (i.metadata?.length) {
            improvement.metadata = i.metadata.map(m => ({
              name: m.name,
              type: m.type,
              answer: m.answer,
            }))
          }
          // if (i.associatedCase) {
          //   improvement.associatedCase = { link: i.associatedCase }
          // }
          return improvement
        })
      }
    }

      const query = {
        query: addEvent,
        variables: { data: newEvent },
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(({data}) => {
            if (data.errors) throw new Error(data.errors[0].message)
            resolve(data.data.insertOneEvent._id)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    async updateEvent(ctx, { event, singleUpload, commitmentFunctionality }) {
      const now = new Date()
      const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)

      const updatedEvent = {
        attendee: event.extendedProps.attendee ? { link: event.extendedProps.attendee._id } : null,
        process: event.extendedProps.process ? { link: event.extendedProps.process._id } : null
      }

      if (event.extendedProps.metadata) {
        updatedEvent.metadata = event.extendedProps.metadata
      }

      if (event.extendedProps.improvements && singleUpload) {
        // Upload images to AWS and then store image keys in an array
        const uploadImagePromises = event.extendedProps.improvements.map(i => {
          if (i._id || !i.imgData?.fileInfo) return null
          const { fileInfo, destinationFolder } = i.imgData
          return new Promise((resolve, reject) => {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => resolve(key))
              .catch((err) => {
                console.log(err)
                resolve(null)
              })
          })
        })

        if (event.extendedProps.participants) {
          updatedEvent.participants = { link: event.extendedProps.participants }
        }

        const imageKeys = await Promise.all(uploadImagePromises)

        const improvementCreationPromises = event.extendedProps.improvements.map((i, index) => {
          if (i._id) return i._id

          // Create new improvement
          const newImprovement = {
            client_id: i.client_id,
            assignee: i.assignee ? { link: i.assignee._id } : null,
            note: i.note,
            tags: i.tags,
            description: i.description,
            dueDate: i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null,
            important: i.important,
            origin: "event",
            origin_id: event.id,
          }
          if (i.imgData) newImprovement.imageKey = imageKeys[index]
          if (i.metadata?.length) {
            newImprovement.metadata = i.metadata.map(m => ({
              name: m.name,
              type: m.type,
              answer: m.answer,
            }))
          }
          // if (i.associatedCase) {
          //   newImprovement.associatedCase = { link: i.associatedCase }
          // }

          return new Promise((resolve, reject) => {
            axios
              .post('/graphql', {
                query: addImprovement,
                variables: { data: newImprovement }
              })
              .then(({data}) => {
                if (data.errors) throw new Error(data.errors[0].message)
                resolve(data.data.insertOneImprovement._id)
              })
              .catch((error) => {
                console.log(error)
                resolve(null)
              })
          })
        })

        const ids = await Promise.all(improvementCreationPromises)

        const filteredIds = ids.filter(e => !!e)

        updatedEvent.improvements = {
          link: filteredIds
        }
      }

      updatedEvent.start = event.start instanceof Date ? event.start : dateStringToDate(event.start)
      updatedEvent.end = event.end instanceof Date ? event.end : dateStringToDate(event.end)

      if (!commitmentFunctionality || event.end <= event.start) {
        updatedEvent.end = new Date(updatedEvent.start)
        updatedEvent.end.setHours(updatedEvent.end.getHours() + 1 )
      }

      // Set calendar status
      updatedEvent.extendedProps = { calendar: event.extendedProps.calendar }
      if (event.extendedProps.calendar !== 'Realizadas') {
        updatedEvent.start.getTime() < now.getTime() ? updatedEvent.extendedProps.calendar = 'Vencidas' : null
        updatedEvent.start.getTime() >= now.getTime() ? updatedEvent.extendedProps.calendar = 'Pendientes' : null
        updatedEvent.start.getTime() >= firstDayOfNextMonth.getTime() ? updatedEvent.extendedProps.calendar = 'Futuras' : null
      }
      
      updatedEvent.modifiedAt = new Date()

      const query = {
        query: updateEvent,
        variables: {
          query: { _id: event.id },
          data: updatedEvent,
        },
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    removeEvent(ctx, { eventData, deletedJustification, deletedBy, improvementsToDelete }) {
      // Delete improvements associated with problem solving
      if (eventData.extendedProps.improvements?.length) {
        eventData.extendedProps.improvements.forEach(i => {
          if (i._id) {
            axios
              .post('/graphql', {
                query: updateImprovement,
                variables: { query: { _id: i._id }, data: { deleted: true } }
              })
              .then(() => { })
              .catch((error) => {
                console.log(error)
              })
          }
        })
      }

      // Delete improvements already flagged for deletion
      if (improvementsToDelete?.length) {
        improvementsToDelete.forEach(i => {
          axios
            .post('/graphql', {
              query: updateImprovement,
              variables: { query: { _id: i._id }, data: { deleted: true } }
            })
            .then(() => {})
            .catch((error) => {
              console.log(error)
            })
        })
      }
      
      // Hard delete
      // const query = {
      //   query: removeEvent,
      //   variables: { query: { _id: id } },
      // }

      // Soft delete
      const query = {
        query: updateEvent,
        variables: {
          query: { _id: eventData.id },
          data: {
            deleted: true,
            deletedJustification,
            deletedBy: { link: deletedBy }
          }
        }
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/graphql', query)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
